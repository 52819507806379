/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-underscore-dangle */
import { FC, Fragment, memo, useMemo, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import type { Page } from '@framework/api/types';
import { getRegion } from '@framework/api/utils/maxify';
import { Experiment } from '@amplitude/experiment-js-client';
import { MAXIFY_CURRENCY_MAP_PER_REGION, ROUTE_ABOUT } from '@constants';
import MarkdownSection from '@components/Page/MarkdownSection';
import Notice from '@components/Page/Notice';
import RiverItem from '@components/RiverItem';
import HeroTall from '@components/HeroTall';
import HeroCentered from '@components/HeroCentered';
import SimpleCardGrid from '@components/SimpleCardGrid';
import MarketingCardSection from '@components/MarketingCards/MarketingCardSection';
import DetailsSection from '@components/DetailsSection';
import CrftFeature from '@components/Home/CrftFeature';
import Hero from '@components/Hero';
import CtaSection from '@components/Cta/CtaSection';
import IconNav from '@components/IconNav';
import FAQSection from '@components/FAQSection';
import Testimonials from '@components/Testimonials';
import SectionWrapper from '@components/SectionWrapper';
import ContentWrapper from '@components/ContentWrapper';
import IconSection from '@components/IconSection';
import VideoBlock from '@components/VideoBlock';
import MarkdownIconBlockSection from '@components/MarkdownIconBlockSection';
import ProseWrapper from '@components/ProseWrapper';
import SignUpSection from '@components/SignUpSection';
import CollectionCardSection from '@components/CollectionCard';
import RelatedItems from '@components/PDP/RelatedItems';
import AnimatedZoomSection from '@components/AnimatedZoomSection';
import VideoHero from '@components/VideoHero';
import LifeStyleCarousel from '@components/LifeStyleCarousel';
import MediaObject from '@components/MediaObject';
import ProductCollectionGrid from '@components/ProductCollectionGrid';
import CountdownHero from '@components/CountdownHero';
import ContentGrid from '@components/ContentGrid';
import LogoCloud from '@components/LogoCloud';
import VideoCarousel from '@components/VideoCarousel';
import HeroBlock from '@components/HeroBlock';
import Scroller from '@components/Scroller';

import * as styles from './styles';

import PageTitle from './PageTitle';

type Props = {
  page: Page;
  titleStyles?: string;
  showTitle?: boolean;
  useProse?: boolean;
};

const amplitudeId = process.env.NEXT_PUBLIC_AMPLITUDE_ID;

const PageComponent: FC<Props> = ({
  page,
  showTitle,
  titleStyles,
  useProse,
}) => {
  const router = useRouter();
  const { locale, asPath } = router;
  const region = getRegion(locale);
  const currencyCode = useMemo(
    () => MAXIFY_CURRENCY_MAP_PER_REGION[region],
    [region]
  );

  const [experimentVariant, setExperimentVariant] = useState(null);

  useEffect(() => {
    const initExperiment = async (): Promise<void> => {
      const experiment =
        Experiment.initializeWithAmplitudeAnalytics(amplitudeId);

      await experiment.start();

      const variant = experiment.variant('category-carousel');

      if (variant && variant.value) {
        setExperimentVariant(variant.value);
      }
    };

    if (amplitudeId) {
      initExperiment();
    }
  }, [asPath]);

  // TODO: Doing this is not scalable, will need a ticket to update MarkdownSection
  // with a new component to properly display this (SectionWrapper & Content Wrapper alone do not resolve issue)
  const limitMarkdownSectionWidth = asPath?.includes(ROUTE_ABOUT);
  const hasCountdownHero =
    page?.countdownHero && Object.keys(page?.countdownHero)?.length > 0;

  const hasCollectionGridProducts =
    page?.collectionGridItems &&
    Object.keys(page?.collectionGridItems)?.length > 0;
  const hasLifeStyleProducts =
    page?.featuredLifeStyleItems &&
    Object.keys(page?.featuredLifeStyleItems)?.length > 0;
  const hasProductGridProducts =
    page?.productGridCards && Object.keys(page?.productGridCards)?.length > 0;
  const hasMarketingCollection =
    page?.marketingProducts && Object.keys(page?.marketingProducts)?.length > 0;
  const hasRelatedProducts =
    page?.relatedProducts && Object.keys(page?.relatedProducts)?.length > 0;

  // Begin Category Carousel A/B/C test
  const filteredContent = useMemo(() => {
    if (!page?.content) return [];

    if (!experimentVariant || experimentVariant === 'control') {
      return page.content.filter(
        section => section._modelApiKey !== 'category_carousel'
      );
    }

    if (experimentVariant === 'treatment-1') {
      const content = [...page.content];
      const carouselIndex = content.findIndex(
        section => section._modelApiKey === 'category_carousel'
      );
      if (carouselIndex !== -1) {
        const heroIndex = content.findIndex(
          section => section._modelApiKey === 'hero_block'
        );
        if (heroIndex !== -1) {
          const [carousel] = content.splice(carouselIndex, 1);
          content.splice(heroIndex, 0, carousel);
        } else {
          return page.content.filter(
            section => section._modelApiKey !== 'category_carousel'
          );
        }
      }
      return content;
    }

    return page.content;
  }, [page?.content, experimentVariant]);

  // End Category Carousel A/B/C test

  return (
    <>
      {showTitle && page.title && (
        <>
          {useProse ? (
            <ProseWrapper isHeading>
              <PageTitle
                title={page.heading || page.title}
                titleStyles={titleStyles}
              />
            </ProseWrapper>
          ) : (
            <PageTitle
              title={page.heading || page.title}
              titleStyles={titleStyles}
            />
          )}
        </>
      )}
      {/* Category Carousel A/B/C test — `filteredContent` replaces `page?.content?` */}
      {filteredContent.map(
        (s, i) =>
          s.id && (
            <Fragment key={s.id}>
              {(() => {
                switch (s._modelApiKey) {
                  case 'category_carousel':
                    return <Scroller items={s} />;

                  // CRFT Hero
                  case 'crft_hero':
                    return <CrftFeature {...s} />;

                  // CTA Block
                  case 'cta_block':
                    return (
                      <SectionWrapper data-test-id="cta-block">
                        <CtaSection ctaData={s.cta} />
                      </SectionWrapper>
                    );

                  // Email Signup Section
                  case 'email_sign_up_section':
                    return <SignUpSection {...s} />;

                  // FAQ Section
                  case 'faq_section':
                    return <FAQSection {...s} />;

                  // Hero Centered
                  case 'hero_centered':
                    return <HeroCentered {...s} />;

                  // Hero
                  case 'hero_primary':
                    return <Hero {...s} />;

                  // Hero Tall
                  case 'hero_tall':
                    return <HeroTall {...s} />;

                  // Featured Icons
                  case 'icon_block':
                    return (
                      <SectionWrapper data-test-id="icon-block">
                        <IconSection {...s} hasBg data={s.iconBlock} />
                      </SectionWrapper>
                    );

                  // Icon Nav Collection
                  case 'icon_nav_collection':
                    return <IconNav {...s} />;

                  // Markdown Content
                  case 'markdown_content':
                    return (
                      <>
                        {useProse ? (
                          <ProseWrapper>
                            <MarkdownSection
                              {...s}
                              applyWidthLimit={limitMarkdownSectionWidth}
                            />
                          </ProseWrapper>
                        ) : (
                          <MarkdownSection
                            {...s}
                            applyWidthLimit={limitMarkdownSectionWidth}
                          />
                        )}
                      </>
                    );

                  // Markdown Icon Section
                  case 'markdown_icon_section':
                    return (
                      <MarkdownIconBlockSection
                        markdownIconBlocks={s.markdownIconBlocks}
                      />
                    );

                  // Marketing Card 2x2 Collection
                  case 'marketing_card2_x2_collection':
                    return (
                      <SectionWrapper data-test-id="marketing-card2-x2-block">
                        <MarketingCardSection
                          {...s}
                          marketingCardData={s.marketingCard}
                        />
                      </SectionWrapper>
                    );

                  // Marketing Product Card
                  case 'marketing_product_card':
                    return hasMarketingCollection ? (
                      <CollectionCardSection
                        collectionData={
                          hasMarketingCollection &&
                          page?.marketingProducts[s?.id]
                        }
                      />
                    ) : null;

                  // Marketing 3-Card Collection
                  case 'marketing_three_card_collection':
                    return (
                      <SectionWrapper data-test-id="marketing-three-card-block">
                        <MarketingCardSection
                          threeCardGrid
                          {...s}
                          marketingCardData={s.marketingCard}
                        />
                      </SectionWrapper>
                    );

                  // Page Notice Collection
                  case 'page_notice_collection':
                    return (
                      <ContentWrapper data-test-id="page-notice-collection-block">
                        <SectionWrapper className={styles.spacingNotices}>
                          <Notice {...s} pageNotice={s.pageNotice} />
                        </SectionWrapper>
                      </ContentWrapper>
                    );

                  // River Item
                  case 'river':
                    return <RiverItem {...s} isEven={i % 2 === 0} />;

                  // Simple Card Grid
                  case 'simple_card_grid':
                    return (
                      <SimpleCardGrid
                        simpleCardGridData={s.simpleCards}
                        heading={s.sectionHeading}
                        {...s}
                      />
                    );

                  // Support Section
                  case 'support_section':
                    return (
                      <DetailsSection
                        heading={s.sectionHeading}
                        linkBlocks={s.linkBlocks}
                        markdownFields={s.markdownFields}
                      />
                    );

                  // Testimonial Collection
                  case 'testimonal_collection':
                    return <Testimonials testimonialsData={s.testimonials} />;

                  // Video Block
                  case 'video_block':
                    return (
                      <VideoBlock
                        heading={s.heading}
                        eyebrow={s.eyebrow}
                        subheading={s.subheading}
                        video={s.video}
                        videoPoster={s.videoPoster}
                        buttonLabel={s.buttonLabel}
                        buttonLink={s.internalButtonLink}
                        isFullBleed={s.isFullbleed}
                        variableSpacing={s.variableSpacing}
                        darkText={s.darkText}
                      />
                    );

                  // Video Carousel
                  case 'video_carousel':
                    return <VideoCarousel {...s} />;

                  // Related Product Cards
                  case 'related_product':
                    return hasRelatedProducts ? (
                      <RelatedItems
                        {...s}
                        products={page?.relatedProducts[s?.id]}
                        currencyCode={currencyCode}
                      />
                    ) : null;

                  case 'image_scroll_jack':
                    return <AnimatedZoomSection {...s} />;
                  // Video hero (but also supports no video)
                  case 'video_hero':
                    return (
                      <VideoHero
                        {...s}
                        altHeading={page?.heading || page?.title}
                      />
                    );

                  // Life Style Carousel
                  case 'life_style_carousel':
                    return (
                      <LifeStyleCarousel
                        {...s}
                        currencyCode={currencyCode}
                        featuredProducts={
                          hasLifeStyleProducts &&
                          page?.featuredLifeStyleItems[s?.id]
                        }
                      />
                    );

                  // Media Object Section
                  case 'media_object_section':
                    return <MediaObject {...s} />;

                  // Product Collection Grid
                  case 'product_collection_grid':
                    return hasCollectionGridProducts ? (
                      <ProductCollectionGrid
                        currencyCode={currencyCode}
                        products={page?.collectionGridItems[s?.id]}
                        eyebrowLogo={s?.eyebrowLogo}
                        heading={s?.heading}
                        subheading={s?.subheading}
                        eyebrow={s?.eyebrow}
                        columnCount={s?.columnCount}
                        collectionName={page?.heading || page?.title}
                        blockType="product-collection-grid-block"
                      />
                    ) : null;

                  // Product Grid
                  case 'product_grid':
                    return hasProductGridProducts ? (
                      <ProductCollectionGrid
                        currencyCode={currencyCode}
                        products={page?.productGridCards[s?.id]}
                        eyebrowLogo={s?.eyebrowLogo}
                        heading={s?.heading}
                        subheading={s?.subheading}
                        eyebrow={s?.eyebrow}
                        columnCount={s?.columnCount}
                        collectionName={page?.title}
                        isExtendedCard={s?.isExtended}
                        textPosition={s?.textPosition}
                        backgroundColor={s?.backgroundColor?.hex}
                        blockType="product-grid-block"
                      />
                    ) : null;

                  // Countdown Hero
                  case 'countdown_hero':
                    return hasCountdownHero ? (
                      <CountdownHero {...page?.countdownHero[s?.id]} />
                    ) : null;

                  // Grid
                  case 'content_grid':
                    return <ContentGrid {...s} />;

                  // Logo Cloud
                  case 'logo_cloud':
                    return <LogoCloud {...s} />;

                  // Enhanced Hero
                  case 'hero_block':
                    return <HeroBlock {...s?.hero} />;

                  default:
                    return null;
                }
              })()}
            </Fragment>
          )
      )}
    </>
  );
};

export default memo(PageComponent);
